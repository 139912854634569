import U11 from "../images/U11.jpg"
import U13 from "../images/U13.jpg"
import U9 from "../images/U9.jpg"
import Page from '../layouts/Page';

export default function HockeyMineur() {

    return (
        <Page titlePage="Hockey mineur" pictureBand={U13}>
            <main className="card card--on-banner">
                    <h2 className='card__title'>L'équipe de U13</h2>
                    <div className='card__img-container'>
                        <img src={U13} alt="equipe de U13 du metz hockey club" />
                    </div>
                    <h2 className='card__title'>L'équipe de U11</h2>
                    <div className='card__img-container'>
                        <img src={U11} alt="equipe de U11 du metz hockey club" />
                    </div>
                    <h2 className='card__title'>L'équipe de U9</h2>
                    <div className='card__img-container'>
                        <img src={U9} alt="equipe de U9 du metz hockey club" />
                    </div>
                </main>
        </Page>
    );
}