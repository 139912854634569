import picture1 from "../images/mhc-1.webp"
import picture2 from "../images/mhc-2.webp"
import picture3 from "../images/mhc-3.webp"
import picture4 from "../images/mhc-4.webp"
import picture5 from "../images/mhc-5.webp"
import picture6 from "../images/mhc-6.webp"
import logo from "../images/logo-metz-hockey-club.webp"
import logoDB from "../images/logo-db.webp"
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer className="footer">
            <aside className="footer-headband">
                <ul className="footer-headband__list">
                    <li className="footer-headband__item-left">
                        <div className="footer-headband__img-wrapper">
                            <img src={picture2} className="footer-headband__img" alt="metz hockey club" />
                        </div>
                    </li>
                    <li className="footer-headband__item-left">
                        <div className="footer-headband__img-wrapper">
                            <img src={picture5} className="footer-headband__img" alt="boutique metz hockey club" />
                        </div>
                    </li>
                    <li className="footer-headband__item-left">
                        <div className="footer-headband__img-wrapper">
                            <img src={picture3} className="footer-headband__img" alt="joueurs du metz hockey club" />
                        </div>
                    </li>
                    <li className="footer-headband__item footer-headband__item--logo-wrapper">
                        <a href="_hockey_index.html" className="footer-headband__logo">
                            <img src={logo} alt="logo du metz hockey club" />
                        </a>
                    </li>
                    <li className="footer-headband__item-right">
                        <div className="footer-headband__img-wrapper">
                            <img src={picture4} className="footer-headband__img" alt="metz hockey club durant un match" />
                        </div>
                    </li>
                    <li className="footer-headband__item-right">
                        <div className="footer-headband__img-wrapper">
                            <img src={picture6} className="footer-headband__img" alt="un joueur de hockey" />
                        </div>
                    </li>
                    <li className="footer-headband__item-right">
                        <div className="footer-headband__img-wrapper">
                            <img src={picture1} className="footer-headband__img" alt="jeunes joueurs du metz hockey club" />
                        </div>
                    </li>
                </ul>
            </aside>
            <div className="footer-inner">
                <div className="footer-inner__container max-content">
                    <div className="footer-inner__item">
                        <span className="footer-inner__item__title">Adresse du MHC</span>
                        <span className="footer-inner__item__information">Patinoire Ice Arena metz</span>
                        <span className="footer-inner__item__information">4B Boulevard Saint Symphorien</span>
                        <span className="footer-inner__item__information">57050 Longeville-Lès-Metz</span>
                    </div>
                    <div className="footer-inner__item">
                        <span className="footer-inner__item__title">Contact MHC</span>
                        <span className="footer-inner__item__information">metzhockeyclub@gmail.com</span>
                        <span className="footer-inner__item__information">06 60 93 13 06</span>
                    </div>
                    <div className="footer-inner__item">
                       <Link to="/protectiondesdonnees">Politique de protection des données</Link>
                    </div>
                    <a href="https://d-bertrand.fr" className="footer-inner__item" target="__blank">
                        <span className="footer-inner__item__title">Un site web créé par</span>
                        <span className="footer-inner__item__information">Delphine Bertrand</span>
                        <div className="footer-inner__item__picture">
                            <img src={logoDB} alt="logo de la société Delphine Bertrand, développeuse web basée à Metz"/>
                        </div>
                        www.d-bertrand.fr
                    </a>
                </div>
            </div>
        </footer>
    )
}