import pictureBand from "../images/mhc-8.webp"
import graoullyCup from "../images/graoully-cup.png"
import Page from '../layouts/Page';

export default function Tournoi() {

    return (
        <Page titlePage="Graoully Cup" pictureBand={pictureBand}>
            <main className="card card--on-banner">
                <h2 className='card__title'>La Graoully Cup</h2>
                <p>
                    La Graoully Cup est un tournoi U13 / U11 qui aura lieu le WE du 17 / 18 et 24 / 25 Mai 2025 à la patinoire de Metz.
                    Le tournoi a la particularité
                    de se dérouler à proximités immédiates des frontières allemande, belge et luxembourgeoise favorisant
                    la rencontre de clubs de tout horizon et différentes nationalités.
                </p>
                <p>
                    The Graoully Cup is a U13 / U11 tournament organized on 17th / 18th and 24th / 25th May 2025 at Metz ice rink.
                    This tournament is
                    special in the way its geographical location, immediately close to the german, belgium and
                    luxembourguish borders, helps to gather different teams coming from different cultures and
                    countries.
                </p>
                <h2 className='card__title'>La plaquette</h2>
                <div className='card__img-container'>
                    <img src={graoullyCup} alt="matériel de hockey" />
                </div>
            </main>
        </Page>
    );
}