import sectionSportive from "../images/section-sportive.jpg"
import Page from '../layouts/Page';
import inscription from "../pdf/section-sportive-inscription.pdf";
import PresentationBarbot from "../images/presentation-barbot.jpg"
import PresentationSection1 from "../images/section-sportive-1.jpg"
import PresentationSection2 from "../images/section-sportive-2.jpg"

export default function SectionSportive() {

    return (
        <Page titlePage="Section sportive" pictureBand={sectionSportive}>
            <main className="card card--on-banner">
                <h2 className='card__title'>Présentation du collège Barbot</h2>
                <img src={PresentationBarbot} alt="Présentation du collège Barbot" />
                <h2 className='card__title'>Plaquette de la section sportive</h2>
                <img src={PresentationSection1} alt="Plaquette de la section sportive" />
                <img src={PresentationSection2} alt="Plaquette de la section sportive" />
                <h2 className='card__title'>Intéressé ?</h2>
                <p className="card__text">Téléchargez et complétez le dossier d'inscription en cliquant sur le bouton ci-dessous, puis envoyez-le par voie postale à l'adresse du collège Barbot :
                    <br/> 2, Avenue de Lattre de Tassigny,
                    <br/> 57000 METZ
                    <br/> ou en main propre auprès de l'entraîneur Axel Berger à la patinoire Ice Arena de Metz.</p>
                <div className='card__img-container'>
                    <a href={inscription} className="button button--link" download rel="noreferrer">télécharger le dossier d'inscription</a>
                </div>
            </main>
        </Page>
    );
}